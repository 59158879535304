body {
  margin: 0;
  font-family: AmazonEmberBold, 'Helvetica Neue Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  background-image: url('./images/bg_cw_landing.jpg');
  background-repeat: no-repeat;
  background-position: center;
  color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logoBlock {
  width: 100%;
}

img.logo {
  padding: 5px;
}

.titleBlock{
  width: 100%;
}

.overview {
  font-size: smaller;
}

.teamSelect {
  width: 200px;
  height: 200px;
  border: 2px solid black;
  border-radius: 50px;
}

.center {
  text-align: center;
  padding: 10% 0;
}

.box-border {
  border: solid 1px;
  border-radius: 25px;
  background: rgba(0,0,0,0.3);
}

.box-border h2 {
  margin: -1px -15px;
  border: 1px solid;
  border-radius: 25px;
  padding: 5px 15px;
  background: black;
}

.box-border .detail {
  margin: 5px auto;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.box-border .response {
  margin-top: 20px;
  margin-bottom: auto;
  font-size: smaller;
}

.box-border .btn-success {
  margin-bottom: 15px;
}

.header .box-border {
  font-size: smaller;
}

span.startTime {
  display: none;
}